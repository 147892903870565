'use strict';

import React, { Component } from 'react';

import styles from './assets/index.module.less';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles.mod1} flex-col`}>
          <div className={`${styles.box1} flex-row`}>
            <div className={`${styles.block1} flex-col`} />
          </div>
          <div className={`${styles.box2} flex-row justify-between`}>
            <img
              className={`${styles.img1}`}
              src={
                require('./assets/img/SketchPng8a87320dfa2de3351aff8e09c54459c98f159806bc5d058f26c1e903381b78ad.png')
              }
            />
            <div className={`${styles.main1} flex-col`}>
              <span className={`${styles.info1}`}>同城拾趣儿</span>
              <span className={`${styles.word1}`}>
                当代年轻人的新交友主义社交平台，在这里你可以找到自己感兴趣的线下活动参与进去，以更自然的方式认识和自己兴趣相投的人。
              </span>
              <div className={`${styles.main2} flex-col`}>
                <div className={`${styles.mod2} flex-row justify-between`}>
                  <img
                    className={`${styles.pic1}`}
                    src={
                      require('./assets/img/SketchPng7f4baa933dcaa210c570a48054972dba8ca242ee66b3bc5e372365f50a59da8e.png')
                    }
                  />
                  <div className={`${styles.TextGroup1} flex-col justify-between`}>
                    <span className={`${styles.word2}`}>微信扫码</span>
                    <span className={`${styles.word3}`}>开启你的有趣旅程</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.box3} flex-row`}>
            <span className={`${styles.word4}`}>Copyright&nbsp;©&nbsp;2022&nbsp;趣加一（南京）网络技术服务有限公司</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
