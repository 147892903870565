import React, {Component} from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import HomeDeep from './view/home-deep/index.jsx'
import Home from './view/home/index.jsx'

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/home-deep" component={HomeDeep} />
                        <Route exact path="/home" component={Home} />
                        <Redirect from="/" to="/home" />
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App
